import React, { useEffect, useState } from "react";

import PostList from "./PostList";
import PostForm from "./PostForm";

const MainFeed = () => {
  const [posts, setPosts] = useState([]);
  const rowHeight = 400;
  const windowHeight = window.innerHeight;

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch("https://my-app.justinsungg.workers.dev/posts");

      const postList = await resp.json();
      setPosts(postList);
    };

    getPosts();
  }, []);

  useEffect(() => {}, [posts.length]);

  return (
    <div style={{
      display: "flex", 
      flexDirection: "column"
    }}>
      <PostForm posts={posts} setPosts={setPosts} />
      <PostList posts={posts} rowHeight={rowHeight} windowHeight={windowHeight}/>
    </div>
  );
};

export default MainFeed;
