import React, { useState } from "react";

const PostList = (props) => {
  const { posts, rowHeight, windowHeight } = props;

  const containerHeight = posts.length * rowHeight; // # of rows * height of each row
  const [scrollTop, setScrollTop] = useState(0);
  const startIndex = Math.floor(scrollTop / rowHeight);
  const endIndex = Math.min(
    posts.length - 1,
    Math.floor((scrollTop + windowHeight) / rowHeight)
  );

  const divs = [];
  for (let i = startIndex; i <= endIndex; i++) {
    divs.push(
      <div
        key={i}
        style={{
          display: "flex",
          flexDirection: "column",
          borderStyle: "solid", 
          borderColor: "black",
          position: "absolute",
          width: "35vw",
          height: rowHeight,
          top: `${i * rowHeight}px`,
        }}
      >
        <h5>{posts[i].username}</h5>
        <h2>{posts[i].title}</h2>
        <p>{posts[i].content}</p>
      </div>
    );
  }

  const handleScroll = (e) => {
    setScrollTop(e.target.scrollTop);
  };

  return (
    <div>
      <h1 style={{textAlign: "center"}}>Posts</h1>
      <div
        style={{
          overflowY: "scroll",
          height: windowHeight,
          width: "auto",
        }}
        onScroll={handleScroll}
      >
        <div
          style={{
            position: "relative",
            height: containerHeight,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {divs}
        </div>
      </div>
    </div>
  );
};

export default PostList;
