import React, { useState } from "react";

const PostForm = ({ posts, setPosts }) => {
  const [charCount, setCharCount] = useState(0);

  const createPost = async (title, username, content) => {
    if (title && username && content) {
      const data = {
        title: title,
        username: username,
        content: content,
      };

      await fetch("https://my-app.justinsungg.workers.dev/posts", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      });

      setPosts([data, ...posts]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const target = e.target;
    const title = target.title.value;
    const content = target.content.value;
    const username = target.username.value;

    await createPost(title, username, content);
    e.target.reset(); 
  };

  const handleChange = (e) => {
    setCharCount(e.target.value.length);
  };

  return (
    <div
      style={{
        maxWidth: "65vw",
        alignSelf: "center",
      }}
    >
      <h1>Create a new post!</h1>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
        onSubmit={handleSubmit}
      >
        <label>Title</label>
        <input type="text" name="title" required />
        <label>Username</label>
        <input type="text" name="username" required />
        <label>Content</label>
        <textarea
          type="text"
          name="content"
          onChange={handleChange}
          required
          maxLength="280"
        />
        <h5>{charCount}/280</h5>
        <button>Submit</button>
      </form>
    </div>
  );
};

export default PostForm;
