import './App.css';
import { Router } from "@reach/router"; 
import MainFeed from "./components/MainFeed"; 

function App() {
  return (
   <Router>
     <MainFeed path="/" />
   </Router>
  );
}

export default App;
